import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Sezione from "../components/sezione"
import SEO from "../components/seo"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import CardPagina from "../components/cardPagina"
import { Container, Row, Col } from "react-bootstrap"

const margin20 = {
  margin: "5px 5px",
}

const Privacy = () => {
  return (
    <Layout isPagina={true}>
      <SEO
        title="Privacy Policy"
        description="Privacy Policy NonsoloEnergia SRL - Trattamento dei dati "
      />
      <BanneronePage title="Privacy Policy" />
      <Container className="mt-4 mb-4">
        <TitoloPagina title="Privacy Policy" />
        <Row>
          <Col>
            <CardPagina variante="bordoLeft">
              <em>Aggiornamento: 08 Giugno 2019</em>
              <p>
                <h2>NonsoloEnergia srl</h2> <br />
                In questa sezione sono contenute tutte le informazioni
                relative alle modalità di gestione di NonsoloEnergia srl in
                riferimento al trattamento dei dati degli utenti ai fini
                dell’art. 13 del D.lgs. n. 196/2003, Codice in materia di
                protezione dei dati personali, e ai fini dell’art. 13 del
                Regolamento UE n. 2016/679.
                <br />
                <h3>Cookies</h3>
                <p>
                  Questo sito web adopera dei cookie per migliorare
                  l'esperienza degli utenti e per ottenere delle statistiche
                  relative al numero dei visitatori Se vuoi avere informazioni
                  sui cookie, clicca{" "}
                  <a
                    href="https://it.wikipedia.org/wiki/Cookie"
                    rel="nofollow"
                    target="_blank"
                  >
                    qui
                  </a>
                </p>
                <p>NonsoloEnergia utilizza Cookie di terze parti:</p>
                <ul>
                  <li>
                    <b>Facebook:</b> questo sito utilizza la Chat per Clienti
                    di Facebook Messanger per interagire con gli utenti e
                    altri plugins per facilitare gli utenti nel trovare la
                    pagina Facebook di riferimento. In tale ottica, questi
                    plugins fanno uso dei cookie già presenti per evitare
                    all'utente di effettuare nuovamente l'autenticazione al
                    social network. Questo sito web non traccia alcuna
                    informazione da questi Social Network, ove possibile.
                  </li>
                  <li>
                    <b>Statistiche e Google Analytics:</b>
                    Questo sito utilizza Google Analytics per ottenere le
                    statistiche di visualizzazione. Il servizio, per ovvi
                    motivi, memorizza un cookie per tracciar l'uso del sito,
                    ma non archivia dati personali.
                  </li>
                  <li>
                    <b>Security:</b>questo sito utilizza{" "}
                    <a
                      href="https://www.cloudflare.com/"
                      target="_blank"
                      rel="nofollow"
                    >
                      Cloudflare
                    </a>{" "}
                    per proteggersi da alcuni attacchi informatici e
                    velocizzare il proprio sito web.
                  </li>
                </ul>
              </p>
              <h2>Informazioni personali trattate</h2>
              <p>
                Per poter svolgere il servizio di richiesta informazioni e
                preventivi, il sito raccoglie le informazioni personali degli
                utenti, <b>sotto loro espresso consenso</b>, tramite la
                compilazione di appositi form.
              </p>
              <p>
                Questi dati vengono raccolti al solo scopo di fornire un
                servizio al cliente (richiesta preventivo e informazioni)
              </p>
              <p>
                Nonsoloenergia non può in alcun modo ottenere le informazioni
                degli utenti, senza il loro espresso consenso.
              </p>
              <p>
                Questi dati, verranno conservati il tempo necessario per
                trattare la richiesta
              </p>
              <p>
                Tali informazioni, non verranno mai cedute a terze parti e
                hanno l'esclusivo scopo di fornire le informazioni richieste
                all'utente (preventivo, richiesta informazioni su servizi)
              </p>
              <h3>Aggiornamenti</h3>
              <p>
                Questa policy può essere soggetta a cambiamenti senza
                preavviso. L'ultima data di aggiornamento è riportata in testa
                alla pagina.
              </p>
            </CardPagina>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Privacy
